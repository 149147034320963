import { cva, type VariantProps } from "class-variance-authority";

import {
  IconBan,
  IconDeviceFloppy,
  IconDownload,
  IconEdit,
  IconEye,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import React from "react";
import { cn } from "~/lib/utils";
import { TIcon } from "./icons";

const iconButtonVariants = cva(" p-[1px] rounded-sm", {
  variants: {
    color: {
      destructive: "text-destructive-foreground hover:bg-destructive",
      primary: "text-primary-foreground hover:bg-primary",
      secondary: "text-secondary-foreground hover:bg-secondary",
      accent: "text-accent-foreground hover:bg-accent",
      default: "text-foreground hover:bg-foreground/30",
      green: "text-green-500 hover:bg-green-300",
      red: "text-red-500 hover:bg-red-300",
      blue: "text-blue-500 hover:bg-blue-300",
      white: "text-white hover:bg-white/30",
      disabled: "text-foreground/50 pointer-events-none",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

export type IconButtonColor = VariantProps<typeof iconButtonVariants>["color"];

export interface IconButtonProps
  extends VariantProps<typeof iconButtonVariants> {
  icon: TIcon;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: string;
  type?: "button" | "submit" | "reset";
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref) => {
    const { icon: Icon, color, onClick, className, size, type } = props;

    return (
      <button
        onClick={onClick}
        className={cn(iconButtonVariants({ color, className }))}
        ref={ref}
        type={type || "button"}
        disabled={color === "disabled"}
      >
        <Icon size={size || "24px"} />
      </button>
    );
  }
);
IconButton.displayName = "IconButton";

export interface PredefinedIconButtonProps
  extends VariantProps<typeof iconButtonVariants> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  size?: string;
  type?: "button" | "submit" | "reset";
}

export const PlusButton = React.forwardRef<
  HTMLButtonElement,
  PredefinedIconButtonProps
>((props: PredefinedIconButtonProps, ref) => {
  return <IconButton icon={IconPlus} {...props} ref={ref} />;
});
PlusButton.displayName = "PlusButton";

export const EditButton = React.forwardRef<
  HTMLButtonElement,
  PredefinedIconButtonProps
>((props: PredefinedIconButtonProps, ref) => {
  return <IconButton icon={IconEdit} {...props} ref={ref} />;
});
EditButton.displayName = "EditButton";

export const DeleteButton = React.forwardRef<
  HTMLButtonElement,
  PredefinedIconButtonProps
>((props: PredefinedIconButtonProps, ref) => {
  return <IconButton icon={IconTrash} {...props} ref={ref} />;
});
DeleteButton.displayName = "DeleteButton";

export const SaveButton = React.forwardRef<
  HTMLButtonElement,
  PredefinedIconButtonProps
>((props: PredefinedIconButtonProps, ref) => {
  return <IconButton icon={IconDeviceFloppy} {...props} ref={ref} />;
});
SaveButton.displayName = "SaveButton";

export const CancelButton = React.forwardRef<
  HTMLButtonElement,
  PredefinedIconButtonProps
>((props: PredefinedIconButtonProps, ref) => {
  return <IconButton icon={IconBan} {...props} ref={ref} />;
});
CancelButton.displayName = "CancelButton";

export const DownloadButton = React.forwardRef<
  HTMLButtonElement,
  PredefinedIconButtonProps
>((props: PredefinedIconButtonProps, ref) => {
  return <IconButton icon={IconDownload} {...props} ref={ref} />;
});
DownloadButton.displayName = "DownloadButton";

export const ViewButton = React.forwardRef<
  HTMLButtonElement,
  PredefinedIconButtonProps
>((props: PredefinedIconButtonProps, ref) => {
  return <IconButton icon={IconEye} {...props} ref={ref} />;
});
ViewButton.displayName = "ViewButton";
